import BootStrapTabs from '../../common/BootStrap/BootStrapTabs';

const SMSTypeTab = ({ setType, type }) => {
    const tabList = [
        {
            title: 'SMS',
            key: 1,
        },
        {
            title: 'LMS',
            key: 2,
        },
    ];

    return <BootStrapTabs tabList={tabList} setValue={setType} defaultKey={type}></BootStrapTabs>;
};

export default SMSTypeTab;
