import axios from 'axios';
import {main_server_ip} from "../communication_system/communication_ips";

const ComUtil = require('./comUtil');

/** URL */
let testLoginUrl = 'https://senor.co.kr/auth/testLogin';
let userPayInfoRefundCheckUrl = 'https://senor.co.kr/userPayInfo/refund/check';
let mainRefundUrl = 'https://senor.co.kr/userPayInfo/refund/check';

if (!ComUtil.IsProduction()) {
    // testLoginUrl = 'https://test.senor.co.kr/userPayInfo/refund/check';
    userPayInfoRefundCheckUrl = 'https://test.senor.co.kr/userPayInfo/refund/check';
    // mainRefundUrl = 'https://test.senor.co.kr/userPayInfo/refund/check';
}

/** 환불신청 및 환불처리 */
export const applyRefund = (data, ref, adminId = null, refreshFunc = null) => {
    const res = axios.post(testLoginUrl, {
        id: data.user_id,
    });

    if (data.pay_contents === 13) {
        const keyRes = axios.post(main_server_ip + "/auth/testLogin", {
            id: adminId,
        });

        // 환불 했을때 결제 내역에서 어떻게 나오는지 봐야함.
        keyRes.then((response) => {
            let userToken = response.data.data.access_token;

            axios.post(main_server_ip + '/userPayInfo/ken/refund', {
                userPayInfoId: data.pay_id
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }).then(
                (res) => {
                    alert('환불 신청이 완료되었습니다.');
                    if (refreshFunc && typeof refreshFunc === 'function')
                        refreshFunc();
                }
            ).catch((err) => {
                console.error(err);
                alert(`[ERROR]${err.response?.data}`);
            })
        }).catch((err) => {
            console.error(err);
            alert('[사용자 인증 에러]관리자에게 문의하세요.');
        });
    } else {
        res.then((response) => {
            let userToken = response.data.data.access_token;
            let param = {
                pay_id: data.pay_id,
                apply_yn: true,
                refund_remark: '관리자 취소',
                account_info: {},
            };
            if (data.pay_type_number === 1) {
                param.account_info.account_num = data.account_no;
                param.account_info.name = data.refund_user_name;
                param.account_info.bank_num = data.bank_code;
            }

            if (!userToken) {
                return alert('인증토큰 발급 후 환불요청이 가능합니다.');
            }
            if (!data.bank_code) {
                return alert('은행정보가 존재하지 않습니다.');
            }

            /** 환불 신청 */
            if (data.pay_status_number === 2) {
                // let testRefundUrl = 'https://test.senor.co.kr/userPayInfo/refund/check';
                // let refundUrl = 'https://senor.co.kr/userPayInfo/refund/check';
                const res = axios.post(mainRefundUrl, param, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                res.then((response) => {
                    alert('환불 신청이 완료되었습니다.');
                }).catch((err) => {
                    console.error(err);
                    alert(`[ERROR]${err.response.data}`);
                });
            }
            /** 환불 처리 */
            if (data.pay_status_number === 3) {
                if (data.pay_type_number === 0 || data.pay_type_number === -1) {
                    // 1. 신용카드(0) 또는 미정(-1)
                    const response = axios.post(userPayInfoRefundCheckUrl, param, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    });

                    response.then((res) => {});
                } else {
                    param = {
                        pay_id: data.pay_id,
                        apply_yn: true,
                        refund_remark: '관리자 취소',
                        account_info: {
                            account_num: data.account_no, // 계좌번호
                            name: data.refund_user_name, // 예금주
                            bank_num: String(data.bank_code), // 은행
                        },
                    };
                    const response = axios.post(userPayInfoRefundCheckUrl, param, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    });
                    response
                        .then((res) => {
                            if (res.data.ok) {
                                alert('환불 처리가 완료되었습니다.');
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                            alert('[환불처리 에러]관리자에게 문의하세요.');
                        });
                }
            }
        }).catch((err) => {
            console.error(err);
            alert('[사용자 인증 에러]관리자에게 문의하세요.');
        });
    }
};

/** 환불신청 및 환불처리(레거시) */
export const regacyApplyRefund = (data) => {
    const response = axios.post(testLoginUrl, {
        id: data.user_id,
    });
    response
        .then((res) => {
            let userToken = res.data.data.access_token;

            let param = {
                pay_id: data.pay_id,
                apply_yn: true,
                refund_remark: '관리자 취소',
            };

            if (!userToken) {
                return alert('인증토큰 발급 후 환불요청이 가능합니다.');
            }
            if (!data.bank_code) {
                return alert('은행정보가 존재하지 않습니다.');
            }

            if (data.pay_type_number === 0 || data.pay_type_number === -1) {
                const response = axios.post(userPayInfoRefundCheckUrl, param, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });

                response.then((res) => {});
            } else if (data.pay_type_number === 1 || data.pay_type_number === 4) {
                param = {
                    pay_id: data.pay_id,
                    apply_yn: true,
                    refund_remark: '관리자 취소',
                    account_info: {
                        account_num: data.account_no, // 계좌번호
                        name: data.refund_user_name, // 예금주
                        bank_num: String(data.bank_code), // 은행
                    },
                };

                const response = axios.post(userPayInfoRefundCheckUrl, param, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });

                response.then((res) => {
                    if (res.data.ok) {
                        alert('환불신청이 완료되었습니다.');
                    }
                });
            }
        })
        .catch((error) => {
            console.error(error);
            alert('[Error] 관리자에게 문의 바랍니다.');
        });
};
