import React, { useState, useRef, useEffect } from 'react';

// styled-components
import styled from 'styled-components';

// react-query && axios
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import axios from 'axios';
// import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';

// BANK CODE
import { BANK_CODE } from '../../SubscribePayment/payBankCodeService';

// components
import LoadingPage from '../../../pages/loading/LoadingPage';
import TicketInfoComponent from '../../CreatePayInfoComponents/TicketInfoComponent';
import ActivityInfoComponent from '../../CreatePayInfoComponents/ActivityInfoComponent';
import ProductInfoComponent from '../../CreatePayInfoComponents/ProductInfoComponent';
import GatheringInfoComponent from '../../CreatePayInfoComponents/GatheringInfoComponent';
import SyrupInfoComponent from '../../CreatePayInfoComponents/SyrupInfoComponent';
import CreateVirtualAccount from '../../SubscribePayment/CreateVirtualAccount';
import UserInfoModal from './UserInfoModal';
import { AiCallPayInfo } from './payInfo';
import ProductInfoComponentForKen from "../../CreatePayInfoComponents/ProductInfoComponentForKen";
import {main_server_ip} from "../../../communication_system/communication_ips";

const ComUtil = require('../../../utils/comUtil');

/** URL */
let url = 'https://senor.co.kr';
// if (!ComUtil.IsProduction()) {
//     url = 'https://test.senor.co.kr';
// }

/** 결제 정보 생성 */
function CreatedPayInfoModal({ setToggle, refresh }) {
    const [myInfo, setMyInfo] = useState({});
    const adminCheckAPI = async () => {
        const reseponse = await RefreshCheckApi.get('/admin/check');
        const adminInfo = reseponse.data.info;
        setMyInfo(adminInfo);
    };

    useEffect(() => {
        adminCheckAPI();
    }, []);

    // state
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [userId, setUserId] = useState(0);
    const [userName, setUserName] = useState('');

    const [packagesData, setPackagesData] = useState([]);

    const [accessToken, setAccessToken] = useState('');
    const [payContents, setPayContents] = useState(null);
    const [payType, setPatType] = useState(1);

    const [loading, setLoading] = useState(false);

    // request param
    const [params, setParams] = useState({});

    // useRef
    const mainModal = useRef();

    // react-query
    const { isLoading, error, data, refetch } = useQuery(['userListsData'], async () => {
        let input = {
            size: 10,
            searchType: searchType,
            searchWord: searchText,
        };

        return await RefreshCheckApi.get('/adminUser', { params: input }).then((res) => {
            return {
                data: res.data.data,
            };
        });
    });

    useEffect(() => {
        setPackagesData(params?.package);
    }, [params]);

    // error 처리
    useEffect(() => {
        if (error) {
            alert(error);
        }
    }, [error]);

    /** 자동 인증 토큰 발급 */
    // useEffect(() => {
    //     if (userId) {
    //         onClickGetToken();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [userId]);

    /********************************** function **********************************/

    // 성별 필터
    const sexFilter = (num) => {
        let _num = parseInt(num);

        if (_num === 1) {
            return '남자';
        } else if (_num === 2) {
            return '여자';
        } else {
            return <span style={{ color: 'gray' }}>NULL</span>;
        }
    };

    // 검색어
    const onChangeInput = (e) => {
        setSearchText(e.target.value);
    };

    // 검색 타입
    const onChangeSearchType = (e) => {
        setSearchType(e.target.value);
    };

    // 검색 요청
    const onChangeSubmit = (e) => {
        e.preventDefault();

        refetch();
    };

    // 결제상품 필터
    const payContentsFilter = (contents) => {
        console.log(`pay contents : ${contents}`);
        switch (Number(contents)) {
            case 0:
                return '1개월 구독권';
            case 1:
                return '3개월 구독권';
            case 2:
                return '놀이';
            case 3:
                return '1개월';
            case 5:
                return '상품';
            case 6:
                return '모임';
            case 7:
                return '시럽';
            case 12:
                return '79전화 이용권';
            case 13:
                return '켄싱턴 상품';
            default:
                return `알수없음 ${contents}`;
        }
    };

    // ! 해당 api는 어째서 어드민 id가 아닌 유저의 id로 토큰을 발급 받는지 확인이 불가능함.
    // 그리고, 해당 기능은 어드민 검증에선 당연히 안될 것이며, 유저 검증에선 필요없는 기능임.
    // 문제는, 로컬 및 개발 디비에서 유저 정보 또한 동기화가 안되기 때문에 로컬에서도 확인이 힘듬.
    /** 토큰 발급 받기 */
    const onClickGetToken = async (serverUrl, adminId) => {
        try {
            const _url = serverUrl || url;
            const response = await RefreshCheckApi.post(_url + '/auth/testLogin', {
                id: adminId ?? userId,
            });
            const token = response.data.data.access_token;
            setAccessToken(token);
            return token;
        } catch (error) {
            console.error('error: ', error);
        }
    };

    /** 회원선택 */
    const onClickSelectUser = (userData) => async () => {
        setAccessToken('');
        setUserId(userData.id);
        setUserName(userData.name);
    };

    // 결제내역
    const onChangePayContents = (e) => {
        setPayContents(parseInt(e.target.value));
    };

    // 결제수단
    const onChangePayType = (e) => {
        setPatType(parseInt(e.target.value));
    };

    /** 입력 검증 */
    const checkGatheringInfo = (params) => {
        if (!parseInt(params.pay_amount)) {
            return alert('결제 가격을 입력해주세요.');
        } else if (!params.gathering_id) {
            return alert('모임을 선택해 주세요.');
        } else if (!params.contents_id) {
            return alert('일정을 선택해 주세요.');
        } else {
            return true;
        }
    };

    // 결제정보 생성
    const onClickCreatePayInfo = () => {
        // 모임 & 현장 결제
        if (payContents === 6 && payType === 5) {
            if (!params.pay_amount || params.pay_amount < 0 || !params.title) {
                return alert('현장 결제 정보를 모두 입력해주세요.');
            }

            onClickGetToken(main_server_ip, myInfo.id).then((token) => {
                setLoading(true);

                const response = axios.post(main_server_ip + '/userPayInfo/site', {
                    user_id: userId,
                    pay_amount: params.pay_amount - (params.point || 0),
                    pay_contents: payContents,
                    pay_name: params.title,
                    pay_status: 1,
                    pay_type: payType,
                    gathering_id: params.gathering_id,
                    contents_id: params.contents_id
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                response
                    .then((res) => {
                        setLoading(false);
                        setToggle(false);

                        if (res.data.data) {
                            refresh();
                            alert('결제정보 생성이 완료되었습니다.');
                        } else {
                            alert(`이상 발생: ${res.data}`);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setLoading(false);
                        alert(`에러발생: ${error.response.data.message}`);
                    });
            })

            return
        }

        // 켄싱턴
        if (payContents === 13) {

            if (!params.payAmount || params.payAmount < 0 ||  !params.payName || !params.state || !params.roomType || !params.headCount || !params.date) {
                return alert('결제 정보를 모두 입력해주세요.');
            }

            onClickGetToken(main_server_ip, myInfo.id).then((token) => {
                setLoading(true);

                const response = axios.post(main_server_ip + '/userPayInfo/ken', {
                    user_id: userId,
                    pay_amount: params.payAmount - (params.point || 0),
                    pay_contents: payContents,
                    pay_name: params.payName,
                    pay_status: 2,
                    refund_id: null,
                    refund_remark: null,
                    use_point_amount: null,
                    pay_type: payType,
                    pay_remark: null,
                    admin_memo: `지역: ${params.state} / 상품명: ${params.payName} / 인원 수: ${params.headCount} / 총액: ${params.payAmount} / 룸 타입: ${params.roomType} / 날짜: ${params.date}`,
                    imp_uid: null,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                response
                    .then((res) => {
                        setLoading(false);
                        setToggle(false);

                        if (res.data.data && res.data.data.length) {
                            refresh();
                            alert('결제정보 생성이 완료되었습니다.');
                        } else {
                            alert(`이상 발생: ${res.data}`);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setLoading(false);
                        alert(`에러발생: ${error.response.data.message}`);
                    });
            })
        } else {
            onClickGetToken().then((token) => {
                let payInfo = {};
                if (params.pay_contents === 6) {
                    checkGatheringInfo({ ...params });
                }

                payInfo = {
                    title: payContentsFilter(payContents),
                    pay_type: payType,
                    pay_contents: payContents,
                    pay_amount: params.pay_amount - (params.point || 0),
                    use_point_amount: parseInt(params.point || 0),
                };

                if (payContents === 6) {
                    payInfo.gathering_id = params.gathering_id;
                    payInfo.contents_id = params.contents_id;
                } else if (payContents === 7) {
                    payInfo.package_id = params.package_id;
                } else if (payContents === 12) {
                    payInfo.pay_remark = params.hour;
                    payInfo.hour = params.hour;
                }

                // 계좌이체: 추가정보 입력
                if (payType === 1) {
                    let accountPayInfo = {
                        bank: params.bank || '039',
                        name: userName,
                        cashReceipt: null,
                    };

                    // 시럽
                    if (payContents === 7) {
                        accountPayInfo.title = `${params.syrup}시럽`;
                    }

                    // 상품
                    if (payContents === 5) {
                        //* 테스트 API 변경 후 회원번호(phone_no) 및 이름(receiver) 수정
                        accountPayInfo = {
                            // bank: params.bank || '039',
                            // title: `상품`,
                            // name: userName,
                            // product_id: 19,
                            // option_id: 10,
                            // count: 1,
                            // delivery_message: '관리자 생성입니다.',
                            // phone_no: '010-2860-6433',
                            // receiver: '김동욱',
                            // address: '-',
                            // address_detail: '-',
                            // cashReceipt: null,
                            // cashReceipt: {
                            //     type: null, // '소득공제',
                            //     registrationNumber: null, // '01043814857',
                            // },
                        };
                    }

                    payInfo = { ...payInfo, ...accountPayInfo };
                }
                setLoading(true);
                const response = axios.post(url + '/userPayInfo', payInfo, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                response
                    .then((res) => {
                        setLoading(false);
                        if (res.data.data.user_pay_info.id) {
                            alert('결제정보 생성이 완료되었습니다.');
                            refresh();
                            setToggle(false);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setLoading(false);
                        alert(`에러발생: ${error.response.data.message}`);
                    });
            });
        }
    };

    /** 조건에 따른 컴포넌트 필터 */
    const componentsFilter = (type) => {
        switch (true) {
            case type === 1:
                return;
            case type === 2:
                return;
            case type === 3:
                return;
            default:
                return <UserInfoModal userId={userId} setUserId={setUserId} setAccessToken={setAccessToken} setPayContents={setPayContents} />;
        }
    };

    /************************************ html ************************************/
    return (
        <DetailModalWrapper id="modal-window">
            <DetailModalBox id="modal-box" ref={mainModal}>
                <Header id="top-line">
                    <Text>결제 정보 생성</Text>
                    <Text style={{ cursor: 'pointer' }} onClick={() => setToggle(false)}>
                        닫기
                    </Text>
                </Header>

                {isLoading ? (
                    <LoadingPage text={'회원정보를 불러오고 있습니다.'} />
                ) : (
                    <ModalBoxBody>
                        <InfoBox className="subscribe-info">
                            {!userId ? (
                                <>
                                    <InfoTitle>회원</InfoTitle>

                                    <SearchWrapper type="submit">
                                        <Select onChange={onChangeSearchType}>
                                            <option value={1}>휴대폰번호</option>
                                            <option value={2}>이름</option>
                                            <option value={3}>닉네임</option>
                                        </Select>

                                        <form style={{ marginLeft: '5px' }} onSubmit={onChangeSubmit}>
                                            <Input type="text" placeholder="회원검색" onChange={onChangeInput} />
                                            <Button type="submit">검색</Button>
                                        </form>
                                    </SearchWrapper>

                                    <ColWrapper>
                                        <Subtitle>회원ID</Subtitle>
                                        <Subtitle>회원명</Subtitle>
                                        <Subtitle>성별</Subtitle>
                                        <Subtitle style={{ width: '85px' }}>생년월일</Subtitle>
                                        <Subtitle style={{ width: '95px' }}>휴대폰번호</Subtitle>
                                    </ColWrapper>

                                    <UserLists>
                                        {data?.data?.map((item, index) => {
                                            return (
                                                <ColWrapper02 key={index} onClick={onClickSelectUser(item)}>
                                                    <SubInfo>{item.id}</SubInfo>
                                                    <SubInfo>{item.name || <span style={{ color: 'gray' }}>NULL</span>}</SubInfo>
                                                    <SubInfo>{sexFilter(item.sex)}</SubInfo>
                                                    <SubInfo style={{ width: '85px' }}>{item.birthday || <span style={{ color: 'gray' }}>NULL</span>}</SubInfo>
                                                    <SubInfo style={{ width: '95px' }}>{item.phone_no || <span style={{ color: 'gray' }}>NULL</span>}</SubInfo>
                                                </ColWrapper02>
                                            );
                                        })}
                                    </UserLists>
                                </>
                            ) : (
                                componentsFilter()
                            )}
                        </InfoBox>

                        <InfoBox className="subscribe-info">
                            <InfoTitle>결제정보 입력</InfoTitle>

                            {userId ? (
                                <div>
                                    <ColWrapper>
                                        <Subtitle>회원ID</Subtitle>
                                        <SubInfo>{userId}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper className="pay-contents">
                                        <Subtitle>결제내용</Subtitle>
                                        <SubInfo>
                                            <Select onChange={onChangePayContents}>
                                                <option value={null}>선택해주세요</option>
                                                {/*<option value={0}>1개월 구독권</option>*/}
                                                {/*<option value={1}>3개월 구독권</option>*/}
                                                {/*<option value={2}>액티비티</option>*/}
                                                {/*<option value={3}>1개월</option>*/}
                                                <option value={5}>상품</option>
                                                <option value={6}>모임</option>
                                                <option value={7}>시럽</option>
                                                <option value={12}>79전화 이용권</option>
                                                <option value={13}>켄싱턴 상품</option>
                                            </Select>
                                        </SubInfo>
                                    </ColWrapper>

                                    {(payContents || payContents === 0) && (
                                        <div>
                                            <ColWrapper className="pay-contents">
                                                <Subtitle>결제수단</Subtitle>
                                                <SubInfo>
                                                    <Select onChange={onChangePayType} value={payType}>
                                                        <option value="">선택해주세요</option>
                                                         <option value={0}>카드결제</option>
                                                        <option value={1}>가상계좌</option>
                                                        {payContents === 6 && <option value={5}>현장결제</option>}
                                                    </Select>
                                                </SubInfo>
                                            </ColWrapper>

                                            <ColWrapper className="pay-contents">
                                                <Subtitle>결제상품</Subtitle>
                                                <SubInfo style={{ width: '100px' }}>{payContentsFilter(payContents)}</SubInfo>
                                            </ColWrapper>
                                        </div>
                                    )}

                                    <ColWrapper style={{ marginTop: '3%', marginBottom: '5%', fontWeight: 'bold' }} />

                                    {payContents === 0 && <TicketInfoComponent type={payContents} />}
                                    {payContents === 1 && <TicketInfoComponent type={payContents} />}
                                    {payContents === 2 && <ActivityInfoComponent setParams={setParams} />}
                                    {payContents === 3 && <TicketInfoComponent type={payContents} />}
                                    {payContents === 5 && <ProductInfoComponent setParams={setParams} />}
                                    {payContents === 6 && <GatheringInfoComponent setParams={setParams} payType={payType} />}
                                    {payContents === 7 && <SyrupInfoComponent setParams={setParams} payType={payType} />}
                                    {payContents === 12 && <AiCallPayInfo setParams={setParams} />}
                                    {payContents === 13 && <ProductInfoComponentForKen setParams={setParams} />}
                                    {payContents && payContents !== 13 && payType === 1 && (
                                        <ColWrapper style={{ alignItems: 'start' }}>
                                            <Subtitle style={{ width: '85px' }}>가상계좌 발급</Subtitle>
                                            <SubInfo>
                                                <CreateVirtualAccount
                                                    params={params}
                                                    setParams={setParams}
                                                    packagesData={payContents === 6 ? [{ pay_name: '모임' }] : packagesData}
                                                    type={payContents === 6 ? '모임' : null}
                                                    payContents={payContents}
                                                />
                                            </SubInfo>
                                        </ColWrapper>
                                    )}
                                </div>
                            ) : (
                                <ColWrapper>
                                    <Subtitle style={{ width: '150px' }}>회원을 선택해주세요.</Subtitle>
                                </ColWrapper>
                            )}
                        </InfoBox>
                    </ModalBoxBody>
                )}
                <ButtonWrapper style={{ textAlign: 'end' }}>
                    <Button02 onClick={onClickCreatePayInfo}>{loading ? '정보 생성중...' : '결제정보 생성'}</Button02>
                </ButtonWrapper>
            </DetailModalBox>
        </DetailModalWrapper>
    );
}

export default CreatedPayInfoModal;

/************************************ styled ************************************/

const DetailModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;

    background-color: #000000b0;
`;

const DetailModalBox = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: start;

    width: 100vh;
    height: 70vh;

    border-radius: 10px;
    background-color: #fff;

    @media (max-width: 912px) {
        width: 98%;
        height: 90vh;
    }

    .close-window {
        padding: 5px 25px;
        text-align: start;
        font-size: 20px;
        color: #428bf7;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;

        padding: 15px;
        color: gray;
    }
`;

const Header = styled.div`
    z-index: 2;
    display: flex;
    justify-content: space-between;

    background-color: rgb(66, 139, 247);

    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

    height: 5vh;

    align-items: center;

    @media (max-width: 1120px) {
        width: 100%;
    }
`;

const Text = styled.div`
    padding: 0 15px;

    color: #fff;
    font-size: 16px;
    font-weight: 600;

    line-height: 45px;
`;

const ModalBoxBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    height: 77vh;
    padding: 0 30px;
    overflow: auto;

    #label {
        display: none;
    }

    @media (max-width: 912px) {
        flex-direction: column;
    }
`;

const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    width: 100%;
    height: 100%;

    border-left: ${(props) => (props.leftLine ? '1px solid gray' : '')};
    padding-left: ${(props) => (props.leftLine ? '2%' : '')};
`;

const InfoTitle = styled.h2`
    margin: 8% 0 30px 0;

    font-size: 17px;
    font-weight: 600;
`;

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
`;

const ColWrapper02 = styled.div`
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;

    padding: 2% 1%;

    &:hover {
        background-color: #cccccc;
    }
`;

const UserLists = styled.div`
    width: 100%;
    height: 50vh;
    overflow: auto;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 60px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 60px;
`;

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1px;

    margin-bottom: 30px;
`;

const Select = styled.select`
    cursor: pointer;
    outline: none;

    border: 1px solid #cccccc;

    width: 90px;
    height: 25px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

const Button = styled.button`
    cursor: pointer;
    border: 1px solid #cccccc;

    width: 50px;
    height: 25px;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;

    width: 100%;
`;

const Button02 = styled.button`
    cursor: pointer;

    border: 1px solid rgb(226, 0, 103);
    border-radius: 2px;

    background-color: rgb(255, 16, 125);
    color: #fff;

    margin: 2%;

    &:hover {
        background-color: rgb(226, 0, 103);
    }
`;
