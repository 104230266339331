import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// custom hooks
import useInput from '../../hook/useInput';

/** 상품정보 */
function ProductInfoComponentForKen({ setParams, setPoint }) {
    const [payAmount, onChangePayAmount] = useInput(0);
    const [payName, onChangePayName] = useInput(null);
    const [state, onChangeState] = useInput(null);
    const [roomType, onChangeRoomType] = useInput(null);
    const [headCount, onChangeHeadCount] = useInput(null);
    const [date, onChangeDate] = useInput(null);

    // 입력값 확인
    useEffect(() => {
        let newParam = {
            payAmount: payAmount,
            payName: payName,
            state: state,
            roomType: roomType,
            headCount: headCount,
            date: date,
        };

        setParams(newParam);
    }, [
        setParams,
        payAmount,
        payName,
        state,
        roomType,
        headCount,
        date,
    ]);

    return (
        <div>
            <ColWrapper className="pay-contents">
                <Subtitle>결제가격</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="결제가격 입력" onChange={onChangePayAmount} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>상품 명</Subtitle>
                <SubInfo>
                    <Input type="text" placeholder="아임웹 상품명과 동일해야 합니다." onChange={onChangePayName} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>지역</Subtitle>
                <SubInfo>
                    <Input type="text" placeholder="강원" onChange={onChangeState} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>룸 타입</Subtitle>
                <SubInfo>
                    <Input type="text" placeholder="디럭스 패밀리" onChange={onChangeRoomType} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>인원 수</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="2, 4, 6명" onChange={onChangeHeadCount} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>일정</Subtitle>
                <SubInfo>
                    <Input type="text" placeholder="1박(2025-03-23 ~ 2025-03-24)" onChange={onChangeDate} />
                </SubInfo>
            </ColWrapper>
        </div>
    );
}

export default ProductInfoComponentForKen;

/********************************* styled *********************************/

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 85px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 60px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;
